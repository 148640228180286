import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../services/user.service';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit
{
  @Input() userUuid: string | null = null;
  resetForm: FormGroup = this.fb.group({});
  passwordChanged: boolean = false;
  displayResetDialog: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit()
  {
    this.resetForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  passwordMatchValidator(group: FormGroup)
  {
    const newPassword = group.get('newPassword')?.value;
    const confirmNewPassword = group.get('confirmNewPassword')?.value;
    return newPassword === confirmNewPassword ? null : { mismatch: true };
  }

  onSubmit() {
    if (this.resetForm.valid)
    {
      const newPassword = this.resetForm.value.newPassword;

      const uuidToUse = this.userUuid ?? this.authService.userId;

      if (!uuidToUse)
      {
        console.error('User UUID is not available!');
        return;
      }

      const passwordHash = this.userService.hashPassword(newPassword);

      this.userService.changePassword(passwordHash, uuidToUse).subscribe({
        next: () => {
          this.passwordChanged = true;
          this.closeDialog();
          this.messageService.add({
            severity: 'success',
            summary: 'Password Changed',
            detail: 'Your password has been changed successfully.',
            life: 3000
          });
        },
        error: (error) => {
          console.error('Error changing password:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Password Change Failed',
            detail: 'There was an error changing your password. Please try again.',
            life: 3000
          });
        }
      });
    }
  }

  openDialog(userUuid?: string)
  {
    this.userUuid = userUuid ?? null;
    this.displayResetDialog = true;
  }

  closeDialog()
  {
    this.displayResetDialog = false;
    this.resetForm.reset();
  }
}
