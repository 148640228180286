<p-button rounded="true" [outlined]="true" severity="success" label="Reset Password" (click)="displayResetDialog = true"></p-button>

<p-dialog 
  [(visible)]="displayResetDialog" 
  [modal]="true" 
  [closable]="false" 
  [header]="'Reset Password'">
  
  <div class="reset-container">
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div class="form-field">
        <label for="newPassword">New Password</label>
        <input id="newPassword" pInputText type="password" formControlName="newPassword" />
        <small *ngIf="resetForm.controls['newPassword'].invalid && resetForm.controls['newPassword'].touched" class="p-error">
          Password must be at least 8 characters long, contain one uppercase letter and one number.
        </small>
      </div>

      <div class="form-field">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input id="confirmNewPassword" pInputText type="password" formControlName="confirmNewPassword" />
        <small *ngIf="resetForm.errors?.mismatch" class="p-error">
          Passwords do not match.
        </small>
      </div>

      <div class="dialog-footer">
        <button pButton type="button" label="Cancel" severity="danger" class="p-button-text" (click)="closeDialog()"></button>
        <button pButton type="submit" label="Change Password" severity="success" [disabled]="resetForm.invalid"></button>
      </div>
    </form>
  </div>

  <div *ngIf="passwordChanged" class="success-message">
    <p>Password changed successfully!</p>
  </div>
</p-dialog>

<p-toast></p-toast>


  
