<form [formGroup]="filterForm">
    <div class="form-container">
      <mat-form-field class="filter-field">
        <mat-label>Filter</mat-label>
        <input matInput formControlName="filter" placeholder="Type to filter">
      </mat-form-field>
  
      <mat-form-field class="dropdown-field">
        <mat-label>User Status:</mat-label>
        <mat-select formControlName="selectedUserStatus" placeholder="Select User Status">
          <mat-option *ngFor="let status of userStatuses" [value]="status.id">{{ status.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <button mat-raised-button color="primary" (click)="addUserDialog()">Add User</button>
      <button mat-raised-button color="primary" (click)="addOrg()">Add Agency</button>
    </div>
</form>

<div class="table-container">
  <table mat-table [dataSource]="users" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
        <td mat-cell *matCellDef="let user">
          <div>
            <div class="icon-and-name">
              <span class="clickable-data" (click)="viewOrg(user.orgUuid)">{{ user.orgName }}</span>
              <div *ngIf="user.orgUuid != 'HSNY' && isAdmin">
                <fa-icon [icon]="faCog" (click)="openRolesDialog(currentUserUuid, user.orgUuid)"></fa-icon>
              </div>
            </div>
          </div>
        </td> 
    </ng-container>
  
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user"> {{ user.fullName }} </td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let user" class="clickable-data" (click)="viewUser(user)"> {{ user.email }} </td>
    </ng-container>

    <ng-container matColumnDef="userStatusId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let user" class="clickable-data" (click)="editStatusDialog(user)"> {{ user.userStatusId }} </td>
      </ng-container>
  
    <ng-container matColumnDef="lastLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Login </th>
      <td mat-cell *matCellDef="let user"> {{ user.lastLogin | date:'MM/dd/yyyy' }} </td>
    </ng-container>
  
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item (click)="userAction('resetPassword', user)">
            <mat-icon>refresh</mat-icon>
            Reset Password Email
          </button>
          <button mat-menu-item (click)="userAction('changePassword', user)">
            <mat-icon>edit</mat-icon>
            Change Password Manually
          </button>
        </mat-menu>
      </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<p-dialog 
  [(visible)]="displayResetDialog" 
  [modal]="true" 
  [closable]="false" 
  [header]="'Change Password'"
  class="password-reset-dialog">
  
  <div class="reset-container">
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div class="form-field">
        <label for="newPassword">New Password</label>
        <input id="newPassword" pInputText type="password" formControlName="newPassword" />
        <small *ngIf="resetForm.controls['newPassword'].invalid && resetForm.controls['newPassword'].touched" class="p-error">
          Password must be at least 8 characters long, contain one uppercase letter and one number.
        </small>
      </div>

      <div class="form-field">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input id="confirmNewPassword" pInputText type="password" formControlName="confirmNewPassword" />
        <small *ngIf="resetForm.errors?.mismatch" class="p-error">
          Passwords do not match.
        </small>
      </div>

      <div class="dialog-footer">
        <button pButton type="button" label="Cancel" severity="danger" class="p-button-text" (click)="closeDialog()"></button>
        <button pButton type="submit" label="Change Password" severity="success" [disabled]="resetForm.invalid"></button>
      </div>
    </form>
  </div>

  <div *ngIf="passwordChanged" class="success-message">
    <p>Password changed successfully!</p>
  </div>
</p-dialog>

<p-toast></p-toast>
