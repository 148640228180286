<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faUser"></fa-icon>
            User
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <app-input-text label="Organization" [fc]="organizationNameFC" [disabled]="'true'">
            </app-input-text>
            <app-input-text label="Full Name" [fc]="fullNameFC" [disabled]="viewMode" [maxlength]="255">
            </app-input-text>
            <app-input-text label="Email (username)" [fc]="emailFC" [disabled]="userUuid ? 'true' : viewMode" [maxlength]="255">
            </app-input-text>
            <app-input-text label="Phone" [fc]="phoneFC" [disabled]="viewMode" [maxlength]="255">
            </app-input-text>
            <app-input-enum label="User Status" [fc]="statusFC" [enumValuesNameAndId]="userStatuses"
                [disabled]="viewMode">
            </app-input-enum>

            <div class="row edit-height mt-2">
                <div class="col-4 unedit-padding bold">
                    <label for="primaryContact">Primary Contact</label>
                </div>
            
                <div class="col-8">
                    <span *ngIf="viewMode == true; else enable_checkbox">
                        <app-checkbox [id]="primaryContact" [fc]="primaryContact" [isDisabled]="'true'" pageStyle="user-page">
                        </app-checkbox>
                    </span>
                    <ng-template #enable_checkbox>
                        <app-checkbox [id]="primaryContact" [fc]="primaryContact" [isDisabled]="'false'" pageStyle="user-page">
                        </app-checkbox>
                    </ng-template>
                </div>
            </div>

            <app-input-text label="Creator" [fc]="creatorFC" [disabled]="'true'">
            </app-input-text>
            <app-input-date label="Created" [fc]="createdFC" [disabled]="'true'" [disabledFormat]="'short'">
            </app-input-date>
            <app-input-text label="Modifier" [fc]="modifierFC" [disabled]="'true'">
            </app-input-text>
            <app-input-date label="Modified" [fc]="modifiedFC" [disabled]="'true'" [disabledFormat]="'short'">
            </app-input-date>

            <app-input-image label="Photo" [fc]="photoFC" [disabled]="viewMode"></app-input-image>

            <div class="row mt-4">
                <div class="col bold" style="background-color: lightgrey; font-size: 1.2em;">
                    User Roles
                </div>
            </div>

            <span *ngFor="let agency of userAgencies">
                <div *ngIf="userAgencies.length > 1" class="row mt-3">
                    <div class="col">
                        {{agency.name}}
                    </div>
                </div>
                <span *ngFor="let program of agency.programs">
                    <div class="row mt-2">
                        <div class="col">
                            {{programsById[program.id].name}}
                        </div>
                    </div>

                    <div class="row pt-1" style="background-color: lightgrey;">
                        <div class="col-4" align="right">
                            Type of Module
                        </div>
                        <div class="col-3" align="right">
                            Type of Role
                        </div>
                        <div class="col-3" align="right">
                            County
                        </div>
                    </div>

                    <div *ngIf="!viewMode && canUserPerformAction('assign-role')" class="row pt-1"
                        style="background-color: lightgrey;">
                        <div class="col-4" align="right">
                            <app-input-enum [fc]="program.submissionTypeFC"
                                [enumValuesNameAndId]="program.submissionTypes" [style]="'none'"
                                (change)="submissionTypeChange(program)">
                            </app-input-enum>
                        </div>
                        <div class="col-3" align="right">
                            <app-input-enum [fc]="program.roleFC" [enumValuesNameAndId]="program.availableRoles"
                                [style]="'none'" (change)="roleChange(program)">
                            </app-input-enum>
                        </div>
                        <div class="col-3" align="right">
                            <app-input-enum [fc]="program.jurisdictionFC"
                                [enumValuesNameAndId]="program.availableJurisdictions" [style]="'none'">
                            </app-input-enum>
                        </div>

                        <div class="col-2">
                            <app-button3 label="Add" [clickFunction]="addPermission.bind(this, agency, program)"
                                [icon]="faSave" [small]="true">
                            </app-button3>
                        </div>
                    </div>

                    <span *ngFor="let permission of userPermissions; let i = index">
                        <span *ngFor="let submissionType of submissionTypes">
                            <div *ngIf="submissionType.program_id == program.id
                            && permission.submission_type_id == submissionType.id 
                            && permission.organization_uuid == agency.uuid" class="row">
                                <div class="col-4" align="right">
                                    {{submissionType.name}}
                                </div>
                                <div class="col-3" align="right">
                                    {{userRolesById[permission.role_id].name}}
                                </div>
                                <div class="col-3" align="right">
                                    {{jurisdictionsById[permission.jurisdiction_id].name}}
                                </div>
                                <div class="col-2">
                                    <app-button3 *ngIf="!viewMode" class="mx-1" label="Remove"
                                        [clickFunction]="removePermission.bind(this, i)" [icon]="faTimes"
                                        [small]="true">
                                    </app-button3>
                                </div>
                            </div>
                        </span>
                    </span>
                </span>
            </span>

            <div *ngIf="saveError" class="row mt-2">
                <div class="col bold" style="color: red" align="center">
                    {{saveError}}
                </div>
            </div>

            <div *ngIf="!viewMode" class="row mt-4 mb-1">
                <div class="col mb-4" align="center">
                    <app-button3 *ngIf="userUuid" class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)"
                        [icon]="faBan">
                    </app-button3>
                    <app-button3 *ngIf="!userUuid" class="mx-1" label="Cancel" [clickFunction]="back.bind(this)"
                        [icon]="faBan">
                    </app-button3>
                    <app-button3 *ngIf="!userUuid" class="mx-1" label="Add User" [clickFunction]="addUser.bind(this)"
                        [icon]="faSave" [isFinal]="true">
                    </app-button3>
                    <app-button3 *ngIf="userUuid" class="mx-1" label="Update User"
                        [clickFunction]="updateUser.bind(this)" [icon]="faSave" [isFinal]="true">
                    </app-button3>
                </div>
            </div>
            <div *ngIf="viewMode" class="row mt-4 mb-1">
                <div class="col mb-4" align="center">
                    <app-button3 class="mx-1" label="Back" [clickFunction]="back.bind(this)" [icon]="faChevronLeft">
                    </app-button3>
                    <app-button3 class="mx-1" label="Edit User" [clickFunction]="editUser.bind(this)" [icon]="faEdit">
                    </app-button3>
                    <app-password-reset *ngIf="showPasswordReset"></app-password-reset>
                </div>
            </div>
        </div>
    </div>
</div>