import { Component, OnInit } from '@angular/core';
import { faSave, faBan, faChevronLeft, faEdit, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { AgencyService } from '../agency.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {
    initialized = false
    faUser = faUser
    faBan = faBan
    faSave = faSave
    faEdit = faEdit
    faTimes = faTimes
    faChevronLeft = faChevronLeft
    organizationNameFC = new UntypedFormControl()
    fullNameFC = new UntypedFormControl()
    emailFC = new UntypedFormControl()
    phoneFC = new UntypedFormControl()
    statusFC = new UntypedFormControl("A")
    lastLoginFC = new UntypedFormControl()
    creatorFC = new UntypedFormControl()
    createdFC = new UntypedFormControl()
    modifierFC = new UntypedFormControl()
    modifiedFC = new UntypedFormControl()
    photoFC = new UntypedFormControl()
    primaryContact = new UntypedFormControl(0)

    userAgencies = []

    programs
    programsById = {}
    jurisdictionsById = {}

    userStatuses
    submissionTypes
    userRoles
    userRolesByType
    userRolesById

    organizationTypeId
    organizationUuid

    primeSubmissionTypeRoles
    subSubmissionTypeRoles

    constructor(
        private router: Router,
        private _userService: UserService,
        private agencyService: AgencyService,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) { }

    get userService() {
        return this._userService;
    }

    async getUserStatusesPromise() {
        try {
          const response = await this.userService.getUserStatuses().toPromise();
      
          if (response) {
            this.userStatuses = response.map(status => ({ id: status.id, name: status.name }));
            return null;
          } else {
            throw new Error('Request failed');
          }
        } catch (error) {
          console.error('Error fetching user statuses:', error);
          throw error;
        }
    }
      

    getSubmissionTypesPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getSubmissionTypes().subscribe((response) => {
                if (response.success) {
                    this.submissionTypes = response.result
                    resolve(null)
                } else {
                    reject()
                }
            })
        })
    }

    getUserRolesPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUserRoles().subscribe((response) => {
                if (response.success) {
                    this.userRoles = response.result
                    this.userRolesById = {}
                    this.userRolesByType = {}
                    for (var i = 0; i < response.result.length; ++i) {
                        this.userRolesById[response.result[i].id] = response.result[i]
                        if (!this.userRolesByType[response.result[i]["organization_type_id"]]) {
                            this.userRolesByType[response.result[i]["organization_type_id"]] = []
                        }
                        this.userRolesByType[response.result[i]["organization_type_id"]].push(response.result[i])
                    }
                    resolve(null)
                } else {
                    reject()
                }
            })
        })
    }

    getSubmissionTypesRolesPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUserRoleOptions().subscribe((response) => {
                this.primeSubmissionTypeRoles = {}
                this.subSubmissionTypeRoles = {}
                if (response.success) {

                    var lastSubmissionTypeId = null
                    for (var i = 0; i < response.result.length; ++i) {
                        if (response.result[i].submission_type_id != lastSubmissionTypeId) {
                            lastSubmissionTypeId = response.result[i].submission_type_id

                            this.primeSubmissionTypeRoles[response.result[i].submission_type_id] = []
                            this.subSubmissionTypeRoles[response.result[i].submission_type_id] = []
                        }
                        if (response.result[i].organization_type_id == "PRIME") {
                            this.primeSubmissionTypeRoles[response.result[i].submission_type_id].push({
                                id: response.result[i].role_id,
                                name: response.result[i].role_name
                            })
                        } else {
                            this.subSubmissionTypeRoles[response.result[i].submission_type_id].push({
                                id: response.result[i].role_id,
                                name: response.result[i].role_name
                            })
                        }
                    }
                    resolve(null)
                } else {
                    reject()
                }
            })
        })
    }

    submissionTypeChange(program) {
        if (this.organizationTypeId == "PRIME") {
            program.availableRoles = this.primeSubmissionTypeRoles[program.submissionTypeFC.value]
        } else {
            program.availableRoles = this.subSubmissionTypeRoles[program.submissionTypeFC.value]
            //console.log(program.availableRoles, this.subSubmissionTypeRoles, program.submissionTypeFC.value)
        }
        this.roleChange(program)
    }

    roleChange(program) {
        //console.log("role change to", program)
        program.availableJurisdictions = this.countiesBySubmissionTypeByProgram[program.id][program.submissionTypeFC.value]
        //console.log(program.availableJurisdictions)
    }

    getProgramsPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getPrograms().subscribe((response) => {
                if (response.success) {
                    this.programs = response.result
                    for (var i = 0; i < this.programs.length; ++i) {
                        this.programsById[this.programs[i].id] = this.programs[i]
                    }
                    return resolve(null)
                }
                console.log("error while fetching programs", response)
                reject()
            })
        })
    }

    countiesBySubmissionTypeByProgram = {}
    getCountiesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getCountiesForOrganization(this.organizationUuid).subscribe((response) => {
                //console.log("counties by program response", response)
                if (response.success) {
                    for (var i = 0; i < response.result.length; ++i) {
                        this.jurisdictionsById[response.result[i].id] = response.result[i]
                        if (!this.countiesBySubmissionTypeByProgram[response.result[i].program_id]) {
                            this.countiesBySubmissionTypeByProgram[response.result[i].program_id] = {}
                        }
                        if (!this.countiesBySubmissionTypeByProgram[response.result[i].program_id][response.result[i].submission_type_id]) {
                            this.countiesBySubmissionTypeByProgram[response.result[i].program_id][response.result[i].submission_type_id] = []
                        }
                        this.countiesBySubmissionTypeByProgram[response.result[i].program_id][response.result[i].submission_type_id].push(response.result[i])
                    }
                    return resolve(null)
                }
                console.log("error while fetching counties", response)
                reject()
            })
        })
    }

    userPermissions = []
    getEditUserPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUser(this.userUuid).subscribe((response) => {
                //console.log("user response", response)
                if (response.success) {
                    this.fullNameFC.setValue(response.result[0].full_name)
                    this.emailFC.setValue(response.result[0].email)
                    this.phoneFC.setValue(response.result[0].phone)
                    this.statusFC.setValue(response.result[0].user_status_id)
                    this.lastLoginFC.setValue(response.result[0].last_login)
                    this.creatorFC.setValue(response.result[0].user_creator)
                    this.createdFC.setValue(response.result[0].user_created)
                    this.modifierFC.setValue(response.result[0].user_modifier)
                    this.modifiedFC.setValue(response.result[0].user_modified)
                    this.photoFC.setValue(response.result[0].photo_id)
                    this.primaryContact.setValue(response.result[0].primary_contact)
                    this.organizationNameFC.setValue(response.result[0].organization_name)
                    this.organizationUuid = response.result[0].organization_uuid
                    for (var i = 0; i < response.result.length; ++i) {
                        if (response.result[i].role_id) {
                            this.userPermissions.push({
                                organization_uuid: response.result[i].organization_uuid,
                                role_id: response.result[i].role_id,
                                submission_type_id: response.result[i].submission_type_id,
                                jurisdiction_id: response.result[i].jurisdiction_id,
                            })
                        }
                    }
                } else {
                    console.log("error while fetching user data", response)
                }
                resolve(null)
            })
        })
    }

    processAgencyResponse(response) {
        if (response.success) {
            this.userAgencies = []
            var lastAgency = null
            var lastProgram = null
            for (var i = 0; i < response.result.length; ++i) {
                if (lastAgency == null || lastAgency.uuid != response.result[i].uuid) {
                    lastAgency = {
                        uuid: response.result[i].uuid,
                        name: response.result[i].name,
                        type: response.result[i].organization_type_id,
                        programs: []
                    }
                    lastProgram = null
                    this.userAgencies.push(lastAgency)
                }
                if (response.result[i].program_id != null
                    && (lastProgram == null || lastProgram.id != response.result[i].program_id)) {

                    var submissionTypesByProgram = []
                    for (var j = 0; j < this.submissionTypes.length; ++j) {
                        if (this.submissionTypes[j].program_id == response.result[i].program_id) {
                            submissionTypesByProgram.push(this.submissionTypes[j])
                        }
                    }

                    lastProgram = {
                        id: response.result[i].program_id,
                        jurisdictions: [],
                        submissionTypeFC: new UntypedFormControl(),
                        roleFC: new UntypedFormControl(),
                        jurisdictionFC: new UntypedFormControl(),
                        submissionTypes: submissionTypesByProgram,
                        availableRoles: [],
                        availableJurisdictions: [],
                    }
                    lastAgency.programs.push(lastProgram)
                }
                if (lastProgram != null && response.result[i].jurisdiction_id != null) {
                    lastProgram.jurisdictions.push({ id: response.result[i].jurisdiction_id, name: response.result[i].jurisdiction_name })
                }
            }
        } else {
            console.log("error while fetching agencies", response)
        }
    }

    getEditUserAgenciesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.agenciesByUser(this.userUuid).subscribe((response) => {
                this.processAgencyResponse(response)
                resolve(null)
            })
        })
    }

    getNewUserAgenciesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getAgency(this.createUserOrganizationUuid).subscribe((response) => {
                this.processAgencyResponse(response)
                resolve(null)
            })
        })
    }

    fetchedPermissions
    getUserPermissionsPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUserPermissions().subscribe((response) => {
                //console.log(response)
                if (response.success) {
                    this.fetchedPermissions = response.result
                } else {
                    console.log("get user permissions error")
                    console.log(response)
                }
                resolve(null)
            })
        })
    }

    userParamSubscription

    initParamSubscription() {

        this.userParamSubscription = this.activatedRoute.queryParams.subscribe((params) => {
            this.initialized = false
            this.userUuid = params["userUuid"]
            this.createUserOrganizationUuid = params["createUserOrganizationUuid"]
            this.createUserOrganizationName = params["createUserOrganizationName"]
            this.viewMode = params["viewMode"] == "true"
            this.showPasswordReset = this.authService.userId === this.userUuid;

            var promises2 = []
            if (this.userUuid) {
                promises2.push(this.getEditUserPromise())
                promises2.push(this.getEditUserAgenciesPromise())
            } else {
                this.organizationNameFC.setValue(this.createUserOrganizationName)
                this.organizationUuid = this.createUserOrganizationUuid
                promises2.push(this.getNewUserAgenciesPromise())
            }
            Promise.all(promises2).then(() => {
                return this.getCountiesPromise()
            }).then(() => {
                this.organizationTypeId = this.userAgencies[0].type
                this.initialized = true
            })
        })
    }

    ngOnDestroy() {
        if (this.userParamSubscription) {
            this.userParamSubscription.unsubscribe()
        }
    }

    userUuid
    createUserOrganizationUuid
    createUserOrganizationName
    viewMode
    logedInUserUuid
    showPasswordReset
    ngOnInit()
    {
        var promises = [
            this.getUserStatusesPromise(),
            this.getProgramsPromise(),
            this.getSubmissionTypesPromise(),
            this.getUserRolesPromise(),
            this.getSubmissionTypesRolesPromise(),
            this.getUserPermissionsPromise(),
        ]
        Promise.all(promises).then(() => {
            this.initParamSubscription()
        })
    }

    cancel() {
        this.viewMode = true
    }

    back() {
        window.history.back();
    }

    saveError
    addUser() {
        this.saveError = null

        if(this.primaryContact.value) {
            console.log(this.primaryContact.value)
        }

        if (!this.createUserOrganizationUuid) {
            this.saveError = "organization is not set. Please cancel and retry."
            return
        }

        if (!this.fullNameFC.value) {
            this.saveError = "Please provide a full name."
            return
        }

        if (!this.emailFC.value) {
            this.saveError = "Please provide a username (email)."
            return
        }

        if (!this.userService.validEmail(this.emailFC.value)) {
            this.saveError = "Please provide a valid email address as the username."
            return
        }

        if (!this.statusFC.value) {
            this.saveError = "Please provide a user status."
            return
        }

        this.userService.userEmailExists(this.emailFC.value).subscribe((response) => {
            if (!response.success) {
                this.saveError = "error while checking for duplicate user"
                if (response.message) {
                    this.saveError += ": " + response.message
                }
                return
            }
            if (response.result.userExists) {
                this.saveError = "User already exists. Please use a different email address."
                return
            }
            this.userService.addUser(
                this.createUserOrganizationUuid,
                this.fullNameFC.value,
                this.emailFC.value,
                this.statusFC.value,
                this.phoneFC.value,
                this.photoFC.value,
                this.userPermissions,
                this.primaryContact.value
            ).subscribe((response) => {
                if (response.success) {
                    var snack = "User " + this.emailFC.value + " successfully added"
                    this._snackBar.open(snack, null, { duration: 2000, });
                    this.back()
                } else {
                    console.log("user add error")
                    console.log(response)
                    this.saveError = "Error while saving user"
                    if (response.message) {
                        this.saveError += ": " + response.message
                    }
                }
            })
        })
    }

    updateUser() {
        this.userService.updateUser(
            this.userUuid,
            this.fullNameFC.value,
            this.emailFC.value,
            this.statusFC.value,
            this.phoneFC.value,
            this.photoFC.value,
            this.userPermissions,
            this.primaryContact.value
        ).subscribe((response) => {
            if (response.success) {
                var snack = "User " + this.emailFC.value + " successfully updated"
                this._snackBar.open(snack, null, { duration: 2000, });
                this.back()
            } else {
                console.log("user update error")
                console.log(response)
            }
        })
    }

    editUser() {
        this.viewMode = false
    }

    addPermission(agency, program) {
        //console.log("permissions params", program.submissionTypeFC.value, program.roleFC.value, program.jurisdictionFC.value)
        this.saveError = null
        if (!program.roleFC.value) {
            this.saveError = "Please specify a role."
            return
        }
        if (!program.submissionTypeFC.value) {
            this.saveError = "Please specify a submission type."
            return
        }
        if (!program.jurisdictionFC.value) {
            this.saveError = "Please specify a jurisdiction."
            return
        }

        for (var i = 0; i < this.userPermissions.length; ++i) {
            if (this.userPermissions[i].organization_uuid == agency.uuid
                && this.userPermissions[i].role_id == program.roleFC.value
                && this.userPermissions[i].submission_type_id == program.submissionTypeFC.value
                && this.userPermissions[i].jurisdiction_id == program.jurisdictionFC.value
            ) {
                this.saveError = "Permission already exists."
                return
            }
        }

        this.userPermissions.push({
            organization_uuid: agency.uuid,
            role_id: program.roleFC.value,
            submission_type_id: program.submissionTypeFC.value,
            jurisdiction_id: program.jurisdictionFC.value,
        })
        //console.log("new perms", this.userPermissions)
    }

    removePermission(i) {
        this.userPermissions.splice(i, 1)
    }

    canUserPerformAction(actionId) {
        return this.userService.canUserPerformAction(this.fetchedPermissions, actionId)
    }
}
