import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { UserService } from '../user.service';
import { faEnvelope, faExclamationTriangle, faLock, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { SessionService } from '../session.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    faEnvelope = faEnvelope;
    faLock = faLock;
    faSignInAlt = faSignInAlt
    faExclamationTriangle = faExclamationTriangle
    @ViewChild("username", { read: ElementRef, static: true }) username: ElementRef;
    @ViewChild("password", { read: ElementRef, static: true }) password: ElementRef;
    @ViewChild("loginButton", { read: ElementRef, static: true }) loginButton: ElementRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private userService: UserService,
        private authService: AuthService,
        private sessionService: SessionService
    ) { }

    loginUsername;
    loginError = false;
    loginMessage;
    params;

    private next;
    private dest;

    ngOnInit() {
        this.loginUsername = localStorage.getItem("loginUsernameStored");
        if (localStorage.getItem("authToken")) {
            this.authService.doLogout()
        }
        this.activatedRoute.queryParams.subscribe(params => {
            this.params = params;
            if (params.next) {
                this.next = params.next;
            }
            if (params.dest) {
                this.dest = params.dest;
            }
            if (params.loginMessage) {
                this.loginMessage = params.loginMessage;
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.loginUsername) {
            this.password.nativeElement.focus();
        } else {
            this.username.nativeElement.focus();
        }
    }

    get destination() {
        return this.dest;
    }

    remembermeChanged(event) {
        if (event.target.checked) {
            localStorage.setItem("loginUsernameStored", (<HTMLInputElement>this.username.nativeElement).value);
        } else {
            localStorage.removeItem("loginUsernameStored");
        }
    }

    loginFailure = false;
    loginUser(event) {
        event.preventDefault();
        this.loginFailure = false;
        this.loginError = false;
        this.loginMessage = null;

        var target = event.target;
        var username = target.username.value;
        var password = target.password.value;
        (<HTMLInputElement>this.password.nativeElement).value = "";

        //note: this is not redundant to remembermeChanged
        if (target.rememberme.checked) {
            localStorage.setItem("loginUsernameStored", username);
        } else {
            localStorage.removeItem("loginUsernameStored");
        }

        var hashedPass = new Md5().appendStr(password).end();

        this.httpClient.post('api/auth', {
            username: username,
            password: hashedPass
        }).subscribe((response: any) => {
            if (response.success) {

                localStorage.setItem("authToken", response.token)
                sessionStorage.setItem("access_token", response.access_token)
                const helper = new JwtHelperService()
                const claims = helper.decodeToken(response.access_token)

                this.sessionService.setClaims(claims)


                if (this.next) {
                    var newLocation = this.next + "?dest=" + this.dest + "&token=" + response.token;

                    for (var property in this.params) {
                        if (this.params.hasOwnProperty(property)) {
                            if (property != "dest" && property != "next") {
                                newLocation += "&" + property + "=" + this.params[property];
                            }
                        }
                    }
                    window.location.href = newLocation;
                } else if (this.dest) {
                    var newLocation = this.dest + "?token=" + response.token;

                    for (var property in this.params) {
                        if (this.params.hasOwnProperty(property)) {
                            if (property != "dest" && property != "next") {
                                newLocation += "&" + property + "=" + this.params[property];
                            }
                        }
                    }
                    window.location.href = newLocation;
                } else {
                    this.authService.stillLoggedInCheck();
                    this.userService.navigateToInitialPage();
                }
            } else {
                if (response.message) {
                    this.loginMessage = response.message
                }
                this.loginFailure = true;
            }
        });
    }

    capsLockDetected = false
    detectCapsLock(e) {
        var kc = e.keyCode ? e.keyCode : e.which;
        var sk = e.shiftKey ? e.shiftKey : ((kc == 16) ? true : false);
        if (((kc >= 65 && kc <= 90) && !sk) || ((kc >= 97 && kc <= 122) && sk)) {
            this.capsLockDetected = true
        } else {
            this.capsLockDetected = false
        }
    }
}
