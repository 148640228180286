import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AgencyService } from '../agency.service';
import { SessionService } from '../session.service'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { UsersService } from '../services/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { iOrganization } from '../model/organization.model';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AssignRoleDialog } from '../manage-users/assign-role-dialog.component';
import { iUserStatus } from '../model/user-status.model';
import { iUser } from '../model/user.model';
import { iUserClaims } from '../model/user-claim.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-users-mat',
  templateUrl: './manage-users-mat.component.html',
  styleUrls: ['./manage-users-mat.component.css']
})
export class ManageUsersMatComponent implements OnInit, AfterViewInit
{
  userStatuses: iUserStatus[] = [];
  organizations: iOrganization[] = [];
  users: MatTableDataSource<iUser>;
  claims: iUserClaims;
  currentUserUuid: string;
  isAdmin: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  resetForm: FormGroup = this.fb.group({});
  passwordChanged: boolean = false;
  displayResetDialog: boolean = false;
  userUuid: string;
  filteredColumns: string[] = ['fullName', 'email', 'orgName'];
  displayedColumns: string[] = 
  [
    'orgName',
    'fullName',
    'email',
    'userStatusId',
    'lastLogin',
    'actions'
  ]
  filterForm: FormGroup;
  faCog = faCog


  userActionStatus: { [key: string]: { [key: string]: boolean } } = {};



  constructor(
    private fb: FormBuilder,
    private agencyService: AgencyService,
    private userService: UserService,
    private newUserService: UsersService,
    private sessionService: SessionService,
    private router: Router,
    public dialog: MatDialog,
    private messageService: MessageService
  ) 
  { 
    this.users = new MatTableDataSource([{}])
  }

  loadUserData(): void
  {
    this.userService.getAllUsers().subscribe(
    (result) => {
      // console.log('Users data:', JSON.stringify(result));
      
      this.users.data = result;
      // console.log('Users array populated:', this.users);
      this.applyFilter();
    },
    (error) => {
      console.error('error gettting user data', error);
    });
  }

  loadOrganizationData(): void
  {
    this.agencyService.getAllOrganizations().subscribe(
      (result) => {
        // console.log('Organizations data:', JSON.stringify(result));
    
        this.organizations = result;
        // console.log('Organizations array populated:', this.organizations);
      },
      (error) => {
        console.error('error gettting organization data', error);
      });
  }

  loadUserStatuses(): void 
  {
    this.userService.getUserStatuses().subscribe(
      (result) => {
        // console.log(`User statuses:`, result);
        this.userStatuses = result;
      },
      (error) => {
        console.error('Error loading user statuses:', error);
      }
    );
  }

  loadUserClaims(): void
  {
    this.claims = this.sessionService.getClaims();
    this.currentUserUuid = this.claims.user_id
    // (this.claims)
    if (this.claims.is_admin)
    {
      this.isAdmin = true
    }else
    {
      this.isAdmin = false
      console.log(`User ${this.claims.user_name} is not an Admin`)
    }
  }

  initForm(): void
  {
    this.filterForm = new FormGroup
    ({
      selectedUserStatus: new FormControl('A'),
      filter: new FormControl('')
    })
  }

  applyFilter() 
  {
    const filterValue = this.filterForm.get('filter').value.trim().toLowerCase();
    const selectedUserStatus = this.filterForm.get('selectedUserStatus').value.trim().toLowerCase();
  
    if (filterValue) 
    {
      this.users.filterPredicate = (data: iUser) => {
        const matchesFilter = this.filteredColumns.some(column => {
          const value = data[column] ? data[column].toString().toLowerCase() : '';
          return value.includes(filterValue);
        });
        
        const matchesStatus = !selectedUserStatus ||
          (selectedUserStatus === 'a' && (data['userStatusId'].toLowerCase() === 'a' || data['userStatusId'].toLowerCase() === 'l')) ||
          (selectedUserStatus !== 'a' && data['userStatusId'].toLowerCase() === selectedUserStatus);
  
        return matchesFilter && matchesStatus;
      };
      this.users.filter = filterValue;
    } else if (selectedUserStatus) 
    {
      this.users.filterPredicate = (data: iUser) => {
        const matchesStatus = !selectedUserStatus ||
          (selectedUserStatus === 'a' && (data['userStatusId'].toLowerCase() === 'a' || data['userStatusId'].toLowerCase() === 'l')) ||
          (selectedUserStatus !== 'a' && data['userStatusId'].toLowerCase() === selectedUserStatus);
  
        return matchesStatus;
      };
      this.users.filter = selectedUserStatus;
    } else 
    {
      this.users.filterPredicate = () => true;
    }
  }

  passwordMatchValidator(group: FormGroup)
  {
    const newPassword = group.get('newPassword')?.value;
    const confirmNewPassword = group.get('confirmNewPassword')?.value;
    return newPassword === confirmNewPassword ? null : { mismatch: true };
  }

  onSubmit()
  {
    if (this.resetForm.valid)
    {
      const newPassword = this.resetForm.value.newPassword;

      const uuidToUse = this.userUuid

      if (!uuidToUse)
      {
        console.error('User UUID is not available!');
        return;
      }

      const passwordHash = this.newUserService.hashPassword(newPassword);

      this.newUserService.changePassword(passwordHash, uuidToUse).subscribe({
        next: () => {
          this.passwordChanged = true;
          this.closeDialog();
          this.messageService.add({
            severity: 'success',
            summary: 'Password Changed',
            detail: 'Password has been changed successfully.',
            life: 3000 
          });
        },
        error: (error) => {
          console.error('Error changing password:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Password Change Failed',
            detail: 'There was an error changing the password. Please try again.',
            life: 3000
          });
        }
      });
    }
  }

  openDialog(userUuid?: string)
  {
    this.userUuid = userUuid ?? null;
    this.displayResetDialog = true;
  }

  closeDialog()
  {
    this.displayResetDialog = false;
    this.resetForm.reset();
  }

  userAction(action: string, user: iUser)
  {
    const userUuid = user.uuid;
  
    if (!this.userActionStatus[userUuid])
    {
      this.userActionStatus[userUuid] = {};
    }
  
    switch (action)
    {
      case 'resetPassword':
        this.userService.sendPasswordRecoveryEmail(user.email).subscribe({
          next: (result) => {
            this.handleSuccess(action, userUuid);
          },
          error: (error) => {
            console.warn('error sending email', error);
          }
        });
        break;
  
      case 'lockUser':
        this.userService.lockUser(user.uuid).subscribe({
          next: (result) => {
            window.location.reload();
          },
          error: (error) => {
            console.warn('error locking user', error);
          }
        });
        break;
  
      case 'inactivateUser':
        this.userService.inactivateUser(user.uuid).subscribe({
          next: (result) => {
            window.location.reload();
          },
          error: (error) => {
            console.warn('error inactivating user', error);
          }
        });
        break;
  
      case 'activateUser':
        this.userService.activateUser(user.uuid).subscribe({
          next: (result) => {
            window.location.reload();
          },
          error: (error) => {
            console.warn('error activating user', error);
          }
        });
        break;
  
      case 'changePassword':
        this.openDialog(userUuid);
        break;
  
      default:
        console.warn('Unsupported action:', action);
        break;
    }
  }
  

  handleSuccess(action: string, userUuid: string)
  {
    this.userActionStatus[userUuid][action] = true; 
    // window.location.reload();
  }
  
  viewUser(user: iUser)
  {
    this.router.navigate(["/user"], { queryParams: { userUuid: user.uuid, viewMode: true } })
  }

  viewOrg(organizationUuid: string)
  {
    this.router.navigate(["/agency"], { queryParams: { agencyUuid: organizationUuid } })
  }

  addOrg()
  {
    this.router.navigate(["/agency"])
  }

  editStatusDialog(user: iUser): void
  {
    const dialogRef = this.dialog.open(EditUserStatusDialog, {
      minWidth: '350px',
      data: { user: user, statusOptions: this.userStatuses }
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(!result)
      {
        window.location.reload();
      }
    })
  }

  addUserDialog(): void 
  {
    const dialogRef = this.dialog.open(AddUserOrgSelectDialog, {
      minWidth: '350px',
      data: this.organizations,
    });
  }
  
  openRolesDialog(userUuid: string, orgUuid: string): void
  {
    const dialogRef = this.dialog.open(AssignRoleDialog, {
        width: '550px',
        data: { userId: userUuid, orgId: orgUuid }
    })
    dialogRef.afterClosed().subscribe(result => {
        console.log(result)
    })
  }
  
  ngOnInit(): void 
  {  
    this.loadUserData();
    this.loadUserStatuses();
    this.loadOrganizationData();
    this.loadUserClaims();
    this.initForm();
    this.resetForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
    this.filterForm.valueChanges.subscribe(
      () => {
      this.applyFilter();
    });
  }

  ngAfterViewInit() {
    this.users.sort = this.sort;
  }
}

@Component({
  selector: 'app-add-user-org-select-dialog',
  templateUrl: './add-user-org-select-dialog.html',
  styleUrls: ['./manage-users-mat.component.css']
})
export class AddUserOrgSelectDialog 
{
  organizations: iOrganization[]
  orgForm = new FormGroup({
    organization: new FormControl('', [Validators.required])
  })

  constructor( public dialog: MatDialog,
  @Inject(MAT_DIALOG_DATA) public data: iOrganization[],
  private router: Router) 
  { 
    this.organizations = data.sort((a, b) => a.name.localeCompare(b.name));
  }

  addUser(): void
  {
    const orgUuid = this.orgForm.get('organization').value;
    const selectedOrg = this.organizations.find(org => org.uuid === orgUuid);

    if (selectedOrg)
    {
      const orgName = selectedOrg.name;

      this.router.navigate(["user"], {
        queryParams: {
            createUserOrganizationUuid: orgUuid,
            createUserOrganizationName: orgName,
            viewMode: false,
        }
      })

      this.dialog.closeAll();
    }else
    {
      console.warn('no seleceted org')
    }
  }
}

@Component({
  selector: 'app-edit-user-status-dialog',
  templateUrl: './edit-user-status-dialog.html',
  styleUrls: ['./manage-users-mat.component.css']
})
export class EditUserStatusDialog 
{
  user: iUser
  statusOptions: iUserStatus[]
  userForm = new FormGroup({
    status: new FormControl('', [Validators.required])
  })
  constructor( public dialog: MatDialogRef<EditUserStatusDialog>,
  private userService: UserService,
  @Inject(MAT_DIALOG_DATA) public data: 
  {
    user: iUser,
    statusOptions: iUserStatus[]
  }) 
  { 
    this.user = data.user;
    this.statusOptions = data.statusOptions; 
    this.userForm.get('status').setValue(this.user.userStatusId)
  }

  updateUserStatus()
  {
    const userUuid = this.user.uuid
    const selectedStatus = this.userForm.get('status').value
    if(selectedStatus === this.user.userStatusId)
    {
      this.dialog.close('status unchanged')
      return
    }
    if(selectedStatus === 'A')
    {
      this.userService.activateUser(userUuid).subscribe({
        next: (result) => {
          console.log(result);
        },
        error: (error) => {
          console.error('error while activating user: ', error);
        },
        complete: () => {
          this.dialog.close();
        }
      })
    }else if(selectedStatus === 'I')
    {
      this.userService.inactivateUser(userUuid).subscribe({
        next: (result) => {
          console.log(result);
        },
        error: (error) => {
          console.error('error while inactivating user: ', error);
        },
        complete: () => {
          this.dialog.close();
        }
      })
    }else if(selectedStatus === 'L')
    {
      this.userService.lockUser(userUuid).subscribe({
        next: (result) => {
          console.log(result);
        },
        error: (error) => {
          console.error('error while locking user: ', error);
        },
        complete: () => {
          this.dialog.close();
        }
      })
    }
  }
}
